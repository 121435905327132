import React, {useState} from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import InTreatmentSubmenu from "../../components/template-partials/in-treatment/in-treatment-submenu"
import StemcellImage from "../../components/template-partials/in-treatment/stem-cell-image"
import Tabs from "../../components/template-partials/global-components/tabs/tabs"
import InlineInfoCard from "../../components/template-partials/global-components/inline-infocard"
import QuestionInfoCard from "../../components/template-partials/global-components/question-infocard"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/3.0-headerbg.jpg"
import headingRound from "../../images/high-risk-alt-heading-img.jpg"
import inductionConsolidation from "../../images/1.3_inductionconsolidation.jpg";
import nausea from "../../images/4.1.1_nausea.png";
import fatigue from "../../images/3.2_fatiguev2Asset134.png";
import hairloss from "../../images/3.2_hairloss.png";
import skinIrritation from "../../images/3.2_skinirritation.png";
import antibodyBook from "../../images/3.4.1_antibodybook.png";
import pain from "../../images/4.8.1_pain_signs.png";
import bloodCount from "../../images/4.1.4_bloodcount.png";
import fever from "../../images/3.2.3_fever.png";
import infusion from "../../images/3.2.3_allergicreaction.png";
import lowBloodPressure from "../../images/3.2.3_lowbloodpressure.png";
import chemoLifecycle from "../../images/3.2_1cycleofchemo.png";

import antibody from "../../images/3.2-antibody.jpg";
import radiation from "../../images/3.2-radiation.jpg";
import consolidation from "../../images/3.2-consolidation.jpg";
import stemCellSurgery from "../../images/3.2-stem-cell-surgery.jpg";
import induction from "../../images/3.2-induction.jpg";
import stemCells from "../../images/3.1-stem-cells.jpg";

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const HighRiskpage = () => {
  const [active, setActive] = useState(1);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  //Trick to refire slick on hidden tabby
  const tabChange =(key)=>{
    setActive(key);
  }
  return (
    <Layout>
      <SEO title="High-Risk Neuroblastoma Treatment" description="Due to its aggressive nature, high-risk neuroblastoma needs intense treatment with many therapies. Learn more about these therapies, including chemotherapy, surgery, radiation, and antibody therapy." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Treatment for High-Risk Neuroblastoma" id="in-treatment_high-risk_header_image">
        <h1 className="section__heading section__heading--green h1">Treatment <em>for</em> High-Risk Neuroblastoma</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`In Treatment`}>
              <InTreatmentSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>The intensity of neuroblastoma treatment will vary depending on the <Link rel="glossary" to="/resources/glossary/#risk-group-or-risk-level">risk group</Link> assigned to your child’s disease. If your child has <Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link>, you can find information about their treatment in this section. <Link to="/diagnosis/understanding-treatment/">To learn about risk groups overall, you can find information on the Understanding the Treatment Options page.</Link></p>
                <p>The treatment goal for children with high-risk neuroblastoma is to prevent neuroblastoma from coming back (<Link rel="glossary" to="/resources/glossary/#relapse">relapse</Link>). <Link rel="glossary" to="/resources/glossary/#survival-rate">Survival rates</Link> are poor for high-risk neuroblastoma patients after relapse, and that’s why the treatment for high-risk neuroblastoma uses multiple intensive therapies. </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Multiple therapies are used to treat high-risk neuroblastoma </h3>
            <p><Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">High-risk neuroblastoma</Link> can be aggressive and difficult to treat. It will require intensive treatment with multiple types of therapy. This approach has significantly improved <Link rel="glossary" to="/resources/glossary/#survival-rate">survival rates</Link>, and research is now focused on new treatments to further improve survival rates. </p>
           <p><a rel='guidelines' href="/NCCN-NBL-Guidelines-2024.pdf">See NCCN guidelines for more detailed guidelines.</a></p> 
            <p>The standard treatment regimen for high-risk neuroblastoma at most hospitals is broken into 3 phases and lasts about 18 months.</p>
            <p className="lg_mt-2 mt-2 lg_mb-2 mb-2 overflow-graph">
              <img style={{minWidth:`800px`}} src={inductionConsolidation} alt="High-risk neuroblastoma treatment occurs in 3 phases: induction, consolidation, and postconsolidation"/>
            </p>
          </div>
        </div>
        <div className="row hide-for-large">
          <div className="columns text-center mb-2 overflow-graph">
            <p><small>&larr; Swipe left to right to view all phases &rarr;</small></p>
          </div>
        </div>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">Select a topic to learn more about each phase of treatment. </h4>
            <em>* Please note that some parts of treatment may take place at different hospitals due to need of resources or staffing.</em>
          </div>
        </div>
        <div className="row row--inner align-center mb-2 hide-for-large"><div className="columns shrink"><p><small>← swipe left to right to see all topics →</small></p></div></div>
        <Tabs className="lg_mt-2 mt-2" tabchange={tabChange}>
          <Tabs.Pane name="Induction" key="1">
            <div className="row row--inner">
              <div className="columns">
              <h2 className="h2 tc-barney jost-med">Induction</h2>
              <p><strong>The first phase of your child’s treatment is known as <Link rel="glossary" to="/resources/glossary/#induction">induction</Link>. Induction therapy will include multiple <Link rel="glossary" to="/resources/glossary/#cycle">cycles</Link> of <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link> to shrink the tumor and surgery to remove any remaining tumor. <Link rel="glossary" to="/resources/glossary/#stem-cell">Stem cell</Link> collection will also happen during this phase. This means your child’s stem cells will be collected and stored for use later in treatment, during the <Link rel="glossary" to="/resources/glossary/#consolidation">consolidation</Link> phase.</strong></p>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">Chemotherapy</h4>
                <p>Chemotherapy is a key part of <Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> treatment. Chemotherapy medicines stop cancer cells from growing, either by killing the cells or stopping them from dividing and reproducing.</p>
                <h4 className="h4 tc-viridian jost-med lg_mt-3 mt-3">How is the chemotherapy given?</h4>
                <p>The most common way children with neuroblastoma receive chemotherapy is in their vein through a <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link>. <Link to="/diagnosis/treatment-options/">Learn what a central line is.</Link> </p>
              </div>
              <div className="columns large-4 small-12 text-center large-text-right">
                <FancyImage url={induction} alt="Chemotherapy is a key part of high-risk neuroblastoma treatment."/>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns">
                <p>Additionally, most chemotherapy for high-risk neuroblastoma will be given <Link rel="glossary" to="/resources/glossary/#in-patient">in-patient</Link> (meaning your child is admitted to the hospital). While your child is in the hospital, a team of oncologists, nurses, and other healthcare professionals help take care of them. Nurses will administer chemotherapy and other supportive medications, monitor for side effects, and support your child 24/7 during hospital admissions.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-3 align-justify">
              <div className="columns large-4 small-12 text-center">
                <img src={chemoLifecycle} style={{maxWidth: `200px`}} alt="Induction chemotherapy is administered in 5-8 cycles that includes treatment and rest"/>
              </div>
              <div className="columns large-8 mt-3">
                <h4 className="h4 tc-viridian jost-med">What are chemotherapy cycles?</h4>
                <p>Induction chemotherapy, like other chemotherapies, will be given in cycles. In this case, your child’s chemotherapy will usually include: </p>
                <ul className="ul--dotted">
                  <li>
                    5 to 8 cycles of intensive chemotherapy
                    <ul>
                      <li>1 cycle of chemotherapy is often close to a month long</li>
                      <li>A cycle includes the time that your child is being treated with the chemotherapy as well as the time they are given to rest at home afterward</li>
                      <li>For example, if your child receives 1 week of intensive chemotherapy and then 3 weeks of rest without chemotherapy, that makes 1 complete cycle</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">What chemotherapy medicines will my child receive?</h4>
                <p>Years of research have shown which chemotherapy regimens work well for high-risk neuroblastoma. Your child’s oncologist will decide which chemotherapy regimens to use based on this research. Medicines that may be a part of your child’s chemotherapy regimen include:</p>
                <div className="row">
                  <div className="columns lg_mt-1 mt-1 small-7 large-6">
                    <ul className="ul--dotted">
                      <li>Topotecan</li>
                      <li>Cyclophosphamide</li>
                      <li>Cisplatin</li>
                    </ul>
                  </div>
                  <div className="columns lg_mt-1 mt-1">
                    <ul className="ul--dotted">
                      <li>Etoposide</li>
                      <li>Vincristine</li>
                      <li>Doxorubicin</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <InlineInfoCard className="lg_mt-2 mt-2" text="Speak with your child’s oncologist to learn the specific chemotherapy medicines your child will be given. Depending on the hospital and the severity of your child’s neuroblastoma, the medicines may be used in different combinations and in a different order."/>
            <div className="row row--inner lg_mt-2 mt-3">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">What are the side effects of chemotherapy?</h4>
                <p>Many of the physical changes or side effects due to chemotherapy are not permanent. It is okay to rely on your child’s healthcare team to figure out how to help manage the side effects, and to talk to your child about some of the changes. <Link to="/supportive-care/">Learn more about how to help manage side effects and about supportive care for your child during chemotherapy.</Link></p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h3 className="h3 tc-barney jost-med">Stem cell collection</h3>
                <h4 className="h4 tc-viridian jost-med lg_mt-2 mt-2">What are stem cells and why do they need to be collected?</h4>
                <p className="lg_mt-2 mt-2"><Link rel="glossary" to="/resources/glossary/#stem-cell">Stem cells</Link> are cells that live in the bone marrow and help the body make new blood cells.</p>
                <p>Your child’s oncologist will collect your child’s stem cells during this phase and then freeze them until they are ready for use in the <Link rel="glossary" to="/resources/glossary/#consolidation">consolidation</Link> phase. The stem cells will be infused back into your child through a <Link rel="glossary" to="/resources/glossary/#stem-cell-transplant">stem cell transplant</Link>. This is done to restore the bone marrow following high-dose chemotherapy (<Link rel="glossary" to="/resources/glossary/#myeloablative">myeloablative</Link> <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link>).</p>
                <p>To help prepare for stem cell collection, oncologists will give children a medicine that helps bone marrow make more <Link rel="glossary" to="/resources/glossary/#white-blood-cell">white blood cells</Link> and helps stem cells move into the bloodstream so they can be collected.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-3">
              <div className="columns large-4 small-12 text-center large-text-left">
                <FancyImage url={stemCells} alt="What are stem cells and why do they need to be collected?"/>
              </div>
              <div className="columns mt-2">
                <h4 className="h4 tc-viridian jost-med">How will my child’s stem cells be collected?</h4>
                <p>The process of collecting your child’s stem cells is known as <Link rel="glossary" to="/resources/glossary/#apheresis">apheresis</Link>.</p>
                <p>Oncologists will use a special type of <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link> to draw blood from your child and pass it through a special machine. This machine will filter out your child’s stem cells and then return the rest of the blood to your child. This process usually takes a few hours and may need to be repeated the following day until enough stem cells are collected. The stem cells are then frozen until the transplant.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-5 mt-5">
              <div className="columns">
                <h3 className="h3 tc-barney jost-med">Surgery (also called local control) </h3>
                <p>Surgery will usually occur after a few <Link rel="glossary" to="/resources/glossary/#cycle">cycles</Link> of <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link> so that the tumors are as small as possible and can be easily removed. </p>
                <p>During surgery your child’s surgeon will remove any of your child’s visible tumors. This is called <Link rel="glossary" to="/resources/glossary/#resection">resection</Link>. The extent of your child’s surgery will depend on the exact location and size of their tumor(s). </p>
                <ul className="ul--dotted">
                  <li>In some cases, a complete removal of your child’s tumor(s) may be possible; this is called complete resection</li>
                  <li>If your child’s tumors are in a risky location or wrapped around a large blood vessel, only a partial removal may be possible; this is called partial resection</li>
                </ul>
              </div>
              <div className="columns large-4 small-12 text-center large-text-left">
                <FancyImage url={stemCellSurgery} alt="Surgery (also called local control)"/>
              </div>
            </div>
          </Tabs.Pane>
          <Tabs.Pane name="Consolidation" key="2">
            <div className="row row--inner">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Consolidation</h2>
                <p><strong>The next phase of your child’s treatment for <Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> is known as <Link rel="glossary" to="/resources/glossary/#consolidation">consolidation</Link>. The consolidation phase will usually include:</strong></p>
                <ul className="ul--dotted">
                  <li>High-dose <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link> (<Link rel="glossary" to="/resources/glossary/#myeloablative">myeloablative</Link>) with <Link rel="glossary" to="/resources/glossary/#autologous">autologous</Link> <Link rel="glossary" to="/resources/glossary/#stem-cell-transplant">stem cell transplant</Link></li>
                  <li>Radiation therapy</li>
                </ul>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-2">
              <div className="columns large-4 small-12 text-center large-text-left">
                <FancyImage url={consolidation} alt="The next phase of your child’s treatment for high-risk neuroblastoma is known as consolidation."/>
              </div>
              <div className="columns mt-2">
                <p>Just as in the <Link rel="glossary" to="/resources/glossary/#induction">induction phase</Link>, the exact treatment your child receives will depend on the treatment plan set forth by their oncologist. Most hospitals now use tandem stem-cell transplant (2 transplants back to back) and radiation therapy for consolidation treatment. In a recent clinical trial, children who had 2 transplants experienced comparable side effects and had lower rates of relapse compared to children who received a single transplant. </p>
                <p>The type of transplant that will be used in neuroblastoma is called an autologous stem cell transplant. This process involves giving the child high-dose chemotherapy to kill the cancer cells. This chemotherapy is so strong that it also kills the healthy <Link rel="glossary" to="/resources/glossary/#stem-cell">stem cells</Link> in the child’s bone marrow. For this reason, children are always given a stem cell transplant after high-dose chemotherapy to <Link rel="glossary" to="/resources/glossary/#rescue">rescue</Link> their bone marrow. This is why a stem cell transplant is sometimes called a bone marrow transplant.</p>
                <p><strong>Ask your child’s oncologist about the therapies they plan to use as part of your child’s treatment.</strong></p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h3 className="h3 tc-barney jost-med">Stem cell transplant</h3>
                <h4 className="h4 tc-viridian jost-med lg_mt-1 mt-3">What is a stem cell transplant?</h4>
                <p>You may hear your child’s healthcare team mention <Link rel="glossary" to="/resources/glossary/#stem-cell-transplant">stem cell transplant</Link> as part of treatment. This process includes both high-dose <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link> and <Link rel="glossary" to="/resources/glossary/#autologous">autologous</Link> stem cell transplant.</p>
                <h4 className="h4 tc-viridian jost-med lg_mt-2 mt-2">How is a stem cell transplant done?</h4>
                <p>After your child completes high-dose chemotherapy, the child’s frozen <Link rel="glossary" to="/resources/glossary/#stem-cell">stem cells</Link> are thawed and given as an infusion through their <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link>. The stem cells travel through the child’s bloodstream to the bone marrow.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 m4-4">
              <div className="columns">
                <div className="row">
                  <div className="columns text-center lg_mb-2 lg_mt-1 mt-2 overflow-graph">
                    <StemcellImage alt="After high-dose chemotherapy your child is given an infusion of their thawed stem cells through a central line"/>
                  </div>
                </div>
                {/* Graph Caption */}
                <div className="row hide-for-large">
                  <div className="columns text-center mb-2 overflow-graph">
                    <p><small>&larr; Swipe left to right to view process &rarr;</small></p>
                  </div>
                </div>
                {/* Graph Caption */}
                </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">How long will my child be in the hospital for the consolidation phase?</h4>
                <p>Usually, children will be admitted to the hospital the day before or the day of high-dose chemotherapy and stay until the new stem cells have started making new blood cells. This process usually takes a few weeks, so plan on your child being in the hospital for at least 3 to 4 weeks.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns large-4 small-12 text-center large-text-left">
                <FancyImage url={radiation} alt="Radiation therapy"/>
              </div>
              <div className="columns mt-2">
                <h3 className="h3 tc-barney jost-med">Radiation therapy</h3>
                <p>Radiation therapy kills the neuroblastoma cells by using high-energy rays or particles aimed at a specific area. Radiation may be given to the primary tumor site. Even if the tumor was completely removed, it is possible that a very small (microscopic) amount of tumor was left behind.</p>
                <p>Your child’s oncologist may also recommend radiation to sites of <Link rel="glossary" to="/resources/glossary/#metastatic">metastatic</Link> disease (places where disease has spread) that have not completely responded to other therapies.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">Common side effects of radiation</h4>
                <p>Short-term side effects may include:</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-2">
              <div className="columns">
              {parseInt(active) === 2 &&
                <Slider className="grid-slider" {...settings}>
                  <div className="text-center">
                    <img src={skinIrritation} alt="title" style={{maxWidth: `129px`}}/>
                    <p>Skin irritations that can be like a sunburn (or sometimes more severe)</p>
                  </div>
                  <div className="text-center">
                    <img src={nausea} alt="title" style={{maxWidth: `129px`}}/>
                    <p><Link rel="glossary" to="/resources/glossary/#nausea">Nausea</Link> or <Link rel="glossary" to="/resources/glossary/#diarrhea">diarrhea</Link> if radiation is being directed at the abdomen </p>
                  </div>
                  <div className="text-center">
                    <img src={hairloss} alt="title" style={{maxWidth: `129px`}}/>
                    <p>Hair loss at radiation site</p>
                  </div>
                  <div className="text-center">
                    <img className="lg_mt-2 lg_mb-2" src={fatigue} alt="" style={{maxWidth: `129px`}}/>
                    <p>Fatigue (feeling tired)</p>
                  </div>
                </Slider>
              }
              </div>
            </div>
          </Tabs.Pane>
          <Tabs.Pane name="Postconsolidation" key="3">
            <div className="row row--inner">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Postconsolidation (antibody therapy)</h2>
                <p><strong>The last phase of standard therapy for <Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> is called <Link rel="glossary" to="/resources/glossary/#postconsolidation-also-called-antibody-therapy">postconsolidation</Link> or <Link rel="glossary" to="/resources/glossary/#antibody-therapy">antibody therapy</Link>. This phase of treatment will be given after your child’s <Link rel="glossary" to="/resources/glossary/#stem-cell-transplant">stem cell transplant</Link> and uses monoclonal antibodies, <Link rel="glossary" to="/resources/glossary/#cytokines">cytokines</Link>, and retinoic acid.</strong></p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-3">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Antibody therapy </h2>
                <h4 className="h4 tc-viridian jost-med lg_mt-2 mt-2">How does antibody therapy work?</h4>
                <p><Link rel="glossary" to="/resources/glossary/#antibody-therapy">Antibody therapy</Link> helps to find remaining neuroblastoma cells that are hiding inside of your child’s body so the immune system can destroy them.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">How is antibody therapy given? </h4>
                <p>Antibody therapy is administered through a vein (<Link rel="glossary" to="/resources/glossary/#intravenously">intravenously</Link>) using the <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link>.</p>
                <p>Most hospitals prefer to give antibody therapy in the hospital. This allows for your child to be monitored by their healthcare team and to receive immediate help if they experience any side effects. </p>
                <p>Antibody therapy may be given with other medicines:</p>
                <ul className="ul--dotted">
                  <li>
                  <Link rel="glossary" to="/resources/glossary/#cytokines">Cytokines</Link> to boost the immune system
                  </li>
                  <li>
                    A differentiating agent (<Link rel="glossary" to="/resources/glossary/#cis-retinoic-acid">cis-retinoic acid</Link>) to help neuroblastoma cells mature
                  </li>
                </ul>
              </div>
              <div className="columns large-4 small-12 text-center large-text-left">
                <FancyImage url={antibody} alt="How is antibody therapy given?"/>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-3">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">What are the side effects of antibody therapy? </h4>
                <p>When your child receives antibody therapy, they will also receive premedications (<Link rel="glossary" to="/resources/glossary/#premedications-or-premeds">premeds</Link>) to help reduce the severity of some side effects. However, some side effects may still occur during antibody therapy. Common side effects may include:</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-2">
              <div className="columns">
              {parseInt(active) === 3 &&
                <Slider className="grid-slider grid-slider--large-3" {...settings}>
                  <div className="text-center">
                    <img src={pain} alt="title" style={{maxWidth: `129px`}}/>
                    <p>Pain</p>
                  </div>
                  <div className="text-center">
                    <img src={fever} alt="title" style={{maxWidth: `129px`}}/>
                    <p><Link rel="glossary" to="/resources/glossary/#fever">Fever</Link></p>
                  </div>
                  <div className="text-center">
                    <img src={infusion} alt="title" style={{maxWidth: `119px`}}/>
                    <p>Infusion reaction (allergic reaction)</p>
                  </div>
                  <div className="text-center">
                    <img src={bloodCount} alt="" style={{maxWidth: `129px`}}/>
                    <p>Low blood counts</p>
                  </div>
                  <div className="text-center">
                    <img src={lowBloodPressure} alt="" style={{maxWidth: `119px`}}/>
                    <p>Low blood pressure</p>
                  </div>
                </Slider>
              }
              </div>
            </div>
            <InlineInfoCard className="lg_mt-2 mt-2" text="Antibody therapy has greatly improved survival for children with high-risk neuroblastoma and is an essential component to high-risk neuroblastoma treatment."/>
            <div className="row row--inner lg_mt-3 mt-3">
              <div className="columns">
                <p><strong>After your child completes the last phase of <Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> treatment, it will mark another milestone in your treatment journey. This allows you and your family to start thinking about the future. Once treatment is complete, your child will enter <Link rel="glossary" to="/resources/glossary/#follow-up-care">follow-up care</Link>, where they will be monitored over the next weeks, months, and years.</strong></p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-3 align-center">
              <div className="columns large-8 small-12">
                <div className="row align-middle">
                  <div className="columns small-12 large-shrink text-center">
                    <img src={antibodyBook} style={{maxWidth:`146px` }} alt=""/>
                  </div>
                  <div className="columns">
                    <p><a href="/resources-and-support/skivolos-kid-zone/" className="btn--primary btn--inline">Help your child learn about antibody therapy with The Next Big Adventure of Little Skivolo</a></p>
                  </div>
                </div>
              </div>
            </div>
          </Tabs.Pane>
        </Tabs>
      </Section>

      <Section>
        <QuestionInfoCard heading="What side effects could my child have, based on their specific treatment plan?" text="Find more helpful questions to ask your doctor." link="/supportive-care/"/>
      </Section>


      <InteriorPageFooter>

      <UpNextCard topic="Supportive Care Overview" title="Learn more about side effect management" lead="See how the healthcare team will help your child through treatment with supportive care." url="/supportive-care/" />

      </InteriorPageFooter>


    </Layout>
  )
}

export default HighRiskpage
